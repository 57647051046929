@tailwind base;
@tailwind components;
@tailwind utilities;

.center {
  @apply flex;
  @apply justify-center;
  @apply items-center;
}

#marginReportPage {
  @apply scrollbar-hide
}
