/* montserrat-regular - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('./fonts/montserrat-v13-latin_cyrillic-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/montserrat-v13-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('./fonts/montserrat-v13-latin_cyrillic-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/montserrat-v13-latin_cyrillic-500.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('./fonts/montserrat-v13-latin_cyrillic-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/montserrat-v13-latin_cyrillic-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('./fonts/montserrat-v13-latin_cyrillic-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/montserrat-v13-latin_cyrillic-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-900 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url('./fonts/montserrat-v13-latin_cyrillic-900.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/montserrat-v13-latin_cyrillic-900.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
